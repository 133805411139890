.flip-card-outer {
  margin: 25px 0;
  width: 400px;
  height: 630px;
}

.flip-card-outer .flip-card-inner {
  transform-style: preserve-3d;
  transition: 0.5s linear 0.1s;
  position: relative;
  width: inherit;
  height: inherit;
}
.flip-card-outer .flip-card-inner.hover-trigger:hover {
  transform: rotateY(180deg);
}
.flip-card-outer .flip-card-inner.showBack {
  transform: rotateY(180deg);
}
.flip-card-outer .flip-card-inner .card {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.flip-card-outer .flip-card-inner .card.front {
  transform: rotateY(0);
}
.flip-card-outer .flip-card-inner .card.back {
  transform: rotateY(180deg);
  background-color: #fff;
  color: #2d2d2d;
}

@media screen and (max-width: 500px) {
  .flip-card-outer {
    width: 100%;
    height: 550px;
  }
}

/* @import url("https://use.typekit.net/eys8nph.css"); */

@font-face {
  font-family: "Euro-Bold";
  src: url(./assets/fonts/EurostileBold.woff2) format("woff2");
}
@font-face {
  font-family: "Euro-Normal";
  src: url(./assets/fonts/EuroStyle-Normal.woff2) format("woff2");
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c3941c/00000000000000007735c13d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "work-sans";
  src: url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5636ba/00000000000000007735c15a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xwide";
  src: url("https://use.typekit.net/af/95afb0/00000000000000007735f2a6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/95afb0/00000000000000007735f2a6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/95afb0/00000000000000007735f2a6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xwide";
  src: url("https://use.typekit.net/af/45f977/00000000000000007735f2b8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/45f977/00000000000000007735f2b8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/45f977/00000000000000007735f2b8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xwide";
  src: url("https://use.typekit.net/af/840285/00000000000000007735f2c3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/840285/00000000000000007735f2c3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/840285/00000000000000007735f2c3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xwide";
  src: url("https://use.typekit.net/af/78b1d0/00000000000000007735f2d3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/78b1d0/00000000000000007735f2d3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/78b1d0/00000000000000007735f2d3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xxwide";
  src: url("https://use.typekit.net/af/bfa227/00000000000000007735f2cc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bfa227/00000000000000007735f2cc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bfa227/00000000000000007735f2cc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xxwide";
  src: url("https://use.typekit.net/af/a5330b/00000000000000007735f2d8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a5330b/00000000000000007735f2d8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a5330b/00000000000000007735f2d8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xxwide";
  src: url("https://use.typekit.net/af/a3ca04/00000000000000007735f2d9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a3ca04/00000000000000007735f2d9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a3ca04/00000000000000007735f2d9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "owners-xxwide";
  src: url("https://use.typekit.net/af/ca787f/00000000000000007735f2da/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ca787f/00000000000000007735f2da/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ca787f/00000000000000007735f2da/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "degular";
  src: url("https://use.typekit.net/af/174e41/00000000000000007735c867/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/174e41/00000000000000007735c867/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/174e41/00000000000000007735c867/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "degular";
  src: url("https://use.typekit.net/af/f22e6a/00000000000000007735c869/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f22e6a/00000000000000007735c869/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f22e6a/00000000000000007735c869/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "degular";
  src: url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/36a2f5/00000000000000007735c877/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "degular";
  src: url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/29fa5e/00000000000000007735c87b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

.font-OwnersWide {
  font-family: "owners-xxwide";
}
.font-WorkSans {
  font-family: "work-sans";
}
.font-Degular {
  font-family: "degular";
}

.modal-overlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal-container {
  flex-basis: 50%;
  border-radius: 3px;
}

.upload-art-image-btn {
  height: 170px;
  width: 170px;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.skyBlue {
  background-color: #0758a7;
}

.customBlue {
  background-color: #2692ff;
}

.customYellow {
  background-color: #ffd600;
}

.customBlueText {
  color: #3a99f6;
}

.customBlueBorder {
  border-color: #3a99f6;
}

.heightEightPer {
  height: 10% !important;
}

.background-ribbon {
  background-image: url("./assets/images/ribbon.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position-y: 40%;
  background-position-x: 50%;
}

.new-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.homepage-hero-section {
  background-image: url("./assets/images/SunflowerDaoBackground.png");
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}

.nft-img {
  height: 80%;
  width: 70%;
}

@media (min-width: 640px) {
  .new-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .modal-container {
    flex-basis: 90%;
  }

  .background-ribbon {
    background-size: contain;
    width: 90%;
    margin: auto;
  }
}

/* Slider Custom CSS on Homepage */
.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #fff;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.image-left {
  padding: 20px;
  position: absolute;
  left: -20px;
  opacity: 0.8;
}

.image-right {
  padding: 20px;
  position: absolute;
  right: -20px;
  opacity: 0.8;
}

.image-card {
  background-color: white;
  padding: 20px;
  text-align: center;
  padding-bottom: 50px;
}
.image-card img {
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
  object-fit: cover;
}

.dot___3c3SI.carousel__dot.carousel__dot--0,
.carousel__dot--0,
.carousel__dot--1,
.carousel__dot--2,
.carousel__dot--3 {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 50%;
  margin:  10px;
}
.carousel__dot.carousel__dot--0{
  background-color: white;

}
.carousel__dot--selected {
  background-color: white;
}

@media screen and (max-width: 500px) {
  .image-card img {
    min-width: 150px;
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  .left-arrow {
    left: -20px;
    top: 90%;
  }
  .right-arrow {
    right: -20px;
    top: 90%;
  }
  .slider {
    height: 450px;
  }
}

